import React from "react";
import {Dialog} from "@material-ui/core";
import Privacy from "./Privacy";

export default function Popup(props) {
  const {openPopUp, setOpenPopUp} = props;
  return (
    <Dialog
      open={openPopUp}
      onClose={() => {
        setOpenPopUp(false);
      }}>
      <Privacy openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} />
    </Dialog>
  );
}
