import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React from "react";
import circleGroup9 from "../images/circleGroup9.png";
// import smallEllipse from "../images/smallEllipse.png";
import eye from "../images/eye.png";
import Quality from "../components/Quality";

const useStyles = makeStyles(theme => {
  return {
    container: {
      fontFamily: "Livvic,sans-serrif",
      // overflowX: "hidden"
    },

    circle9: {
      position: "relative",
      overflowY: "hidden",
      background: "#D2E6E4",
    },
    circleGroup9: {
      position: "absolute",
      top: "-141px",
      [theme.breakpoints.down("md")]: {
        transform: "translate(-9%,-10%)",
        width: "auto",
      },
      [theme.breakpoints.down("sm")]: {
        transform: "translate(-9%,-10%)",
        // width: "100%"
      },
      [theme.breakpoints.down("xs")]: {
        transform: "translate(-9%,30%)",
        width: "100%",
      },
    },
    padding: {
      padding: 50,
    },
    circle: {
      padding: "2vw",
      [theme.breakpoints.down("xs")]: {
        marginTop: "-66px",
      },
    },
    text: {
      "position": "relative",
      "display": "inline-block",
      "maxWidth": 600,

      "& p": {
        fontWeight: 400,
        lineHeight: "1.5rem",
      },
    },

    subheading: {
      fontSize: "20px",
    },
    heading: {
      fontSize: 30,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    subHeading: {
      fontWeight: 600,
      fontSize: 20,
    },
    eye: {
      position: "absolute",
      right: "0px",
      bottom: "36px",
      [theme.breakpoints.down("md")]: {
        transform: "translateX(91%)",
      },
      [theme.breakpoints.down("sm")]: {
        transform: "translateX(120%)",
        width: "59px",
        display: "none",
      },
    },
  };
});

const WhyChooseUs = ({data}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.circle9}>
        <div>
          <img src={circleGroup9} className={classes.circleGroup9} alt="img3" />
        </div>
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="center"
          alignItems="center"
          className={classes.container}>
          <Grid item className={[classes.padding, classes.text].join(" ")}>
            <h1 className={classes.heading}>Why Choose us?</h1>
            <h4 className={classes.subheading}>
              We provide the best online service
            </h4>
            <p>{data}</p>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="center"
          alignItems="center"
          className={classes.circle}>
          {/* <CircleLady /> */}
          <Quality />
          <img src={eye} className={classes.eye} alt="img3" />
        </Grid>
      </Grid>
    </>
  );
};

export default WhyChooseUs;
