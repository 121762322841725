import {Button, Grid, InputBase} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useFormik} from "formik";
import React from "react";
import * as Yup from "yup";
import {sendMessage} from "../api";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles(theme => ({
  inputBase: {
    "height": "60px",
    "width": "90%",
    "borderBottom": "1px solid #17664E",
    "paddingLeft": "5px",
    "lineHeight": "0 !important",
    "&::placeholder": {
      color: "#000000",
    },
    [theme.breakpoints.down("md")]: {
      height: "48px",
      // width:'216px'
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
      // width:'131px'
    },
    [theme.breakpoints.down("xs")]: {
      height: "43px",
    },
  },
  title: {
    color: "#17664E",
    fontSize: "16px",
    fontWeight: "700",
  },
  contact: {
    maxWidth: 400,
    background: "#FFFF",
    borderRadius: "20px",
    padding: "16px 10px 16px 35px",
    margin: "0 auto",
    transform: "translateX(10px)",
  },
  button: {
    "color": "#FFFF",
    "height": "60px",
    "marginRight": 30,
    "marginTop": 15,
    "borderRadius": "20px 0px",
    " &:hover": {
      background: "#17664E",
    },
  },
  line: {
    width: "29px",
    height: " 0px",
    border: "1px solid #FFFFFF",
    margin: "0 21px",
  },
}));

const formInputs = ["Name", "Email", "Phone", "Message"];

function ContactUs() {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Minimum 3 characters")
        .max(20, "Characters more than 20 not allowed")
        .required("Required"),
      email: Yup.string().email("Must be valid Email").required("Required"),
      message: Yup.string()
        .min(5, "Minimum 5 characters required")
        .max(60, "Message above 60 characters not allowed")
        .required("Required"),
    }),
    onSubmit: (values, action) => {
      action.setSubmitting(true);
      const formData = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }
      sendMessage(formData)
        .then(() => {
          action.setSubmitting(false);
          action.resetForm();
          toast.success("Message sent successfully");
        })
        .catch(err => {
          action.setSubmitting(false);
          toast.error(err.message);
        });
    },
  });
  return (
    <div className={classes.footerpart}>
      <div className={classes.contact}>
        <h1 className={classes.title}>Any Query?</h1>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}>
          {formInputs.map((placeholder, index) => {
            const name = placeholder.toLowerCase();
            return (
              <>
                <InputBase
                  type={
                    placeholder === "Email"
                      ? "email"
                      : placeholder === "Phone"
                      ? "number"
                      : "text"
                  }
                  key={index}
                  {...formik.getFieldProps(name)}
                  className={classes.inputBase}
                  name={name}
                  placeholder={placeholder}
                />
                {formik.touched[name] && formik.errors[name] && (
                  <p style={{color: "red"}}>{formik.errors[name]}</p>
                )}
              </>
            );
          })}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                disabled={formik.isSubmitting}>
                Send Message <div className={classes.line}></div>
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
