import {Grid, Typography, makeStyles, Box} from "@material-ui/core";
import React, {useState} from "react";
import Popup from "./PopUp";
import {useHistory} from "react-router";
// import Privacy from './Privacy';

const useStyles = makeStyles(theme => ({
  footer: {
    paddingTop: "2%",
    paddingBottom: "2%",
  },
}));

function ThankYouPageFooter({color}) {
  const [openPopUp, setOpenPopUp] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  console.log("color", color);
  return (
    <Grid>
      <Grid
        className={classes.footer}
        container
        justifyContent="center"
        spacing={4}>
        <Grid item>
          <Typography>
            <Box color={color}>@2021</Box>
          </Typography>
        </Grid>
        <div>
          <Grid item style={{maxWidth: 42, margin: "0 16px"}}>
            <p
              onClick={() => history.push("/privacy")}
              style={{color: "#17664e", cursor: "pointer"}}>
              Privacy
            </p>
          </Grid>
        </div>
        {/* <Grid item><Typography>    <Link to="/privacy"><Box style={{ cursor: "pointer" }} color={color} >
                    Privacy
                </Box> </Link></Typography>
                </Grid> */}

        {/* <Privacy /> */}
        <div>
          <Grid item style={{maxWidth: 42, margin: "0 16px"}}>
            <p
              onClick={() => history.push("/terms")}
              style={{color: "#17664e", cursor: "pointer"}}>
              Terms
            </p>
          </Grid>
        </div>
        {/* <Grid item><Typography><Box c>
                    Terms
                </Box></Typography></Grid> */}
      </Grid>

      <Popup openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} />
    </Grid>
  );
}

export default ThankYouPageFooter;
