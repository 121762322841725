import React from "react";
import Header from "../components/Header";
import { Formik } from "formik";
import Stepper from "../components/Stepper";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { postData } from "../api";
import GenerateFormData from "../hooks/GenerateFormData";

function Consult() {
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        symptoms: [],
        eye: "",
        duration: "",
        description: "",
        files: [],
        file1: null,
        file2: null,
        file3: null,
        name: "",
        email: "",
        dob: "",
        contact: "",
        preferVideoConsultation: false,
        consultDate: "",
        paypal_token: "",
        paypal_amount: "",
        scheduleObject: null,
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .min(3, "Must be at least 3 characters ")
          .required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        dob: Yup.date().required("Required"),
        contact: Yup.string()
          .min(8, "Minimum 8 characters")
          .required("Required"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const formdata = GenerateFormData(values);
        setSubmitting(true);
        postData(formdata)
          .then(res => {
            setSubmitting(false);
            console.log(res);
            history.push("/thankyou");
          })
          .catch(err => {
            setSubmitting(false);
            console.error(err);
            alert("Error posting data to the server");
          });
      }}>
      <div>
        <Header />
        <Stepper />
      </div>
    </Formik>
  );
}

export default Consult;
