import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import {Field} from "formik";
import {ReactComponent as CheckIcon} from "../assets/check-icon.svg";

import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: "15px",
  },
  btn: {
    whiteSpace: "normal",
    fontSize: "16px",
    textTransform: "none",
    width: "100%",
    display: "block",
    minHeight: "60px",
    textAlign: "center",
    fontWeight: 700,
    lineHeight: "15.06px",
    [theme.breakpoints.down("md")]: {
      padding: "5px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2px",
      minHeight: "40px",
    },
  },
  short: {
    width: "100%",
    padding: "0 50px 0 0",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: 0,
    },
  },
}));

/**
 * @type {React.FC<{
 * question:string;
 * options:Array<{
 * label:string;
 * value:string
 * nuke?:boolean;
 * }>;
 * name:string;
 * type:"checkbox"|"radio";
 * }>}
 */
const SingleCheckboxSection = ({question, options, name, type}) => {
  const styles = useStyles();

  const nukeObj = options.find(item => {
    return Boolean(item.nuke);
  });
  let nukeVal = "";
  if (nukeObj) {
    nukeVal = nukeObj.value;
  }

  return (
    <Grid container direction="column" item>
      <Typography variant="h6" align="left" className={styles.label}>
        <Box color="#4F4F4F">{question}</Box>
      </Typography>
      <Grid container className={styles.short} spacing={2}>
        {options.map(({label, value}, index) => {
          return (
            <Grid key={index} item xs={6} sm={4}>
              <Field name={name} value={value}>
                {({form}) => (
                  <CustomButton
                    form={form}
                    label={label}
                    name={name}
                    value={value}
                    styles={styles}
                    type={type}
                    nukeVal={nukeVal}
                  />
                )}
              </Field>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const CustomButton = ({name, form, value, label, styles, type, nukeVal}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  if (type === "checkbox") {
    const handleClick = () => {
      console.log(nukeVal);
      const isNuke = value === nukeVal;
      const val = [...form.values[name]];
      let toSet = [];
      if (val.includes(value)) {
        toSet = val.filter(i => i !== value);
      } else {
        toSet = isNuke
          ? [value]
          : [...val, value].filter(v => {
              return v !== nukeVal;
            });
      }
      form.setFieldValue(name, toSet);
    };

    const checked = form.values[name].includes(value);

    return (
      <Button
        onClick={handleClick}
        size={matches ? "small" : "medium"}
        color="primary"
        variant={checked ? "contained" : "outlined"}
        className={styles.btn}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid style={{paddingRight: "10%"}}>{checked && <CheckIcon />}</Grid>
          {label}
        </Grid>
      </Button>
    );
  } else {
    const val = form.values[name];
    return (
      <Button
        onClick={() => {
          form.setFieldValue(name, value);
        }}
        color="primary"
        variant={val === value ? "contained" : "outlined"}
        className={styles.btn}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid style={{paddingRight: "10%"}}>
            {val === value && <CheckIcon />}
          </Grid>
          {label}
        </Grid>
      </Button>
    );
  }
};

export default SingleCheckboxSection;
