import React from "react";
import {PayPalButtons} from "@paypal/react-paypal-js";
import {onPaypalApprove, paypalCreateOrder} from "./PayPalService";

const PaypalPayment = ({amount, afterPaymentComplete}) => {
  return (
    <PayPalButtons
      style={{
        color: "blue",
        shape: "pill",
        label: "pay",
        height: 40,
      }}
      createOrder={(data, actions) => paypalCreateOrder(data, actions, amount)}
      onApprove={(data, actions) =>
        onPaypalApprove(data, actions, afterPaymentComplete)
      }
    />
  );
};
export default PaypalPayment;
