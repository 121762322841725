import React from "react";
import {Route, Switch} from "react-router";
import Home from "./pages/Home";
import Consult from "./pages/Consult";
import ThankYou from "./components/ThankYou";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import Payment from "./pages/Payment";
// import Quality from "./components/Quality";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <div className="App">
      <PayPalScriptProvider
        options={{
          "client-id":
            "AVjoWlZvxzLWPFGxKjwENxAqEADuV4AKdUffZd5fyGxuepWOUtsGYkzs10b8QxZep6dKeMdvslAljrcc",
          "currency": "GBP",
        }}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          {/* i don't know why this is even here */}
          {/* <Route exact path="/quality">
          <Quality />
        </Route> */}

          <Route exact path="/consult">
            <Consult />
          </Route>
          <Route exact path="/thankyou">
            <ThankYou />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/payment/:id">
            <Payment />
          </Route>
          <Route path="*">
            <div>Page not found</div>
          </Route>
        </Switch>
      </PayPalScriptProvider>
    </div>
  );
}

export default App;
