import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React from "react";
import {Link} from "react-router-dom";
import circleo1 from "../images/circleo1.png";
import ellipse from "../images/ellipse.png";
import {useTheme} from "@material-ui/styles";
import {useMediaQuery} from "@material-ui/core";
import {getVirtualeyeImage, DOMAIN} from "../api";
import {toast} from "react-toastify";
import {useQuery} from "react-query";

// import circleGroup1 from "../images/circleGroup1.png";
// import circleGroup2 from "../images/circleGroup2.png";

const useStyles = makeStyles(theme => ({
  container: {
    "boxSizing": "border-box",
    "fontFamily": "Livvic,sans-serrif",
    "overflowX": "hidden",

    "& h2": {
      fontSize: "70px",
      fontWeight: 700,
      color: "#2E2E2E !important",

      [theme.breakpoints.down("md")]: {
        fontSize: "50px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "46px",
      },
    },
    "& p": {
      color: "#303030 !important",
      marginTop: "25px",

      // fontWeight: "bold",
      fontSize: "30px",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
    },
    "& h1": {
      fontSize: 70,
      color: "#2E2E2E !important",
      fontWeight: 700,

      [theme.breakpoints.down("md")]: {
        fontSize: "50px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "46px",
      },
    },
    "& h1,h2": {
      color: theme.palette.primary.main,
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      "& h1": {
        fontSize: 36,
      },
      "& h2": {
        fontSize: 36,
      },
    },
    [theme.breakpoints.down("custom")]: {
      "& h1": {
        fontSize: 33,
      },
      "& h2": {
        fontSize: 20,
      },
    },

    [theme.breakpoints.down("xs")]: {
      "& h1": {
        fontSize: 20,
      },
      "& h2": {
        fontSize: 20,
      },
    },
  },

  headContain: {
    position: "relative",
    marginTop: "25%",
    gridRow: "2/4",

    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
      marginLeft: "45px",
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: "90px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "60%",
      marginLeft: "25px",
    },
  },
  box: {
    width: "90%",
    margin: "0 ",
    // border: "10px solid white",
    borderBottom: "10px solid transparent",
    borderRadius: 10,
    display: "grid",
    gridTemplateColumns: "repeat(9,1fr)",
    gridTemplateRows: "repeat(6,95px)",
    [theme.breakpoints.down("md")]: {
      gridTemplateRows: "repeat(4,95px)",
      // marginBottom: "-25px",
    },

    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },

    [theme.breakpoints.down("sm")]: {},

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  buttonsContainer: {
    "display": "flex",
    "alignItems": "center",
    "marginBottom": "80px",
    "marginTop": "20px",

    "&>*": {
      marginRight: 12,
      textTransform: "Uppercase",
      padding: "8px 30px ",
      fontSize: 26,

      [theme.breakpoints.down("md")]: {
        marginTop: "-20px",
        padding: "10px 20px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "41px",
      marginLeft: "0",
    },
  },
  textContent: {
    gridColumn: "2/5",
    gridRow: "2/3",
    minWidth: 500,
    zIndex: 111,

    [theme.breakpoints.down("md")]: {
      gridColumn: "1/5",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "-53px",
    },
  },

  circleGroup1: {
    gridColumn: "7/9",
    gridRow: "1/2",
    marginTop: "47px",
    marginLeft: "39px",

    [theme.breakpoints.down("md")]: {
      height: "35px !important",
      width: "23px !important",
      marginLeft: "272px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      // height: "20px",
      // width: "24px",
      marginLeft: "195px",
      marginTop: "0px",
    },
  },
  circleGroup2: {
    gridColumn: "9",
    gridRow: "2",
    marginTop: "77px",
    [theme.breakpoints.down("md")]: {
      height: "62px",
      width: "34px",
      marginLeft: "170px",
      marginTop: "-50px",
    },

    [theme.breakpoints.down("xs")]: {
      width: "28",
      height: "28",
      transform: "translateX(25%) translateY(-14%)",
    },
  },

  girl: {
    gridColumn: "6/7",
    gridRow: "1/2",
    [theme.breakpoints.down("md")]: {
      gridColumn: "5/9",
      transform: "scale(0.8) translateX(100%)",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.7) translateX(40%)",
      gridColumn: "8/-1",
    },
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.5) translateX(17%)  translateY(-49%)",
      gridColumn: "9/-1",
    },
  },
  circular: {
    gridColumn: "8/9",
    gridRow: "1/2",
    marginTop: "19px",
    marginLeft: "-39px",
    [theme.breakpoints.down("lg")]: {
      // gridColumn: "9/-1",
      transform: "scale(0.8)",
    },

    [theme.breakpoints.down("md")]: {
      gridColumn: "9/-1",
      transform: "scale(0.8)",
      marginLeft: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      gridRow: "2/3",
      transform: "translate(0,-50px) scale(0.8)",
    },
    [theme.breakpoints.down("xs")]: {
      gridRow: "2/3",
      transform: "translate(49px,-42px) scale(0.5)",
    },
  },
  image: {
    height: "490px",
    position: "absolute",
    top: "140px",
    right: "160px",
    width: "740px",
    zIndex: 111,
    objectFit: "contain",

    [theme.breakpoints.down("xl")]: {
      width: "600px",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
      objectFit: "contain",
      height: "290px",
      right: "50px",
      top: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "400px",

      height: "300px",
      top: "105px",
      right: "30px",
    },

    [theme.breakpoints.down("xs")]: {
      width: "88%",
      height: "235px",

      objectFit: "fill",
      left: "6%",
    },
  },

  circleo1: {
    height: "350px",
    width: "275px",
    position: "absolute",
    top: 0,
    right: 0,

    [theme.breakpoints.down("sm")]: {
      width: "200px",
      height: "260px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  ellipseimg: {
    position: "absolute",
    top: "-12px",
    left: "-33px",
    zIndex: -1,

    [theme.breakpoints.down("md")]: {
      height: "60px",
      top: "170px",
      left: "135px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const Virtual = () => {
  const {data} = useQuery("virtualeyeimage", getVirtualeyeImage, {
    onError: e => toast.error(e.message || "Error fetching data"),
  });
  // console.log(data?.[0]?.image?.url);

  const styles = useStyles(0);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <img
          src={DOMAIN + data?.[0]?.image?.url}
          alt="img"
          className={styles.image}
        />

        <div className={styles.textContent}>
          <div className={styles.headingContainer}>
            <div className={styles.headContain}>
              <h2>Virtual Eye</h2>
              <h1>Consultant</h1>
              <p>We bring eye care to you</p>
              <img src={ellipse} alt="img" className={styles.ellipseimg} />

              <div className={styles.buttonsContainer}>
                <Button
                  component={Link}
                  to="/consult"
                  size={matchesSm ? "small" : matchesMd ? "medium" : "large"}
                  color="primary"
                  variant="contained">
                  Consult now
                </Button>
                {/* <Button
                color="primary"
                size={matchesSm ? "small" : matchesMd ? "medium" : "large"}
                variant="outlined">
                Learn more
              </Button> */}
              </div>
            </div>
          </div>
        </div>
        {/*<img src={girl} className={styles.girl} alt="girl" />
        <img src={circleGroup1} className={styles.circleGroup1} alt="img1" />

        <img src={circular} alt="circular" className={styles.circular} />
        <img src={circleGroup2} className={styles.circleGroup2} alt="img1" />

        */}
        <img src={circleo1} alt="circle" className={styles.circleo1} />
      </div>
    </div>
  );
};

export default Virtual;
