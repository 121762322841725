import React from "react";
import {makeStyles} from "@material-ui/styles";
import "./Quality.scss";
import {useQuery} from "react-query";
import {getWhyChooseUsPoints} from "../api";
import {toast} from "react-toastify";



const useStyles = makeStyles(theme => ({
  header: {
    color: "#17664E",
    fontSize: "16px",
    fontWeight: "600",
    paddingLeft: "10px",
  },
  subHeader: {
    paddingLeft: "10px",
    marginTop: "0",
    lineHeight: "1.5rem",
  },
  qualityCard: {
    margin: "30px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    padding: "15px",
    maxWidth: "400px",
    boxShadow: "0px 4px 8px #cccccc59",
    position: "relative",
    zIndex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    // display: "grid",
    gridTemplateColumns: "50px 1fr",
  },
  contentBox: {
    // display: "flex",
    width: "50px",
  },
  contenticon: {
    display: "block",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundSize: "cover",
    margin: "0px",
  },
  qualityContent: {
    paddingLeft: "10px",
    //marginTop: 25,
    // width: "calc(100% - 65px)",
  },
  integer: {
    position: "absolute",
    left: "32px",
    top: "-52px",
    zIndex: 3,
    color: " rgba(23, 102, 78, 0.2)",
    // fontWeight: 500,
    fontSize: "34px",
  },
  register: {
    position: "relative",
  },
  imageContainer: {
    flex: "0 0 20px",
  },
}));

const Points = () => {
  const {isLoading, data} = useQuery(
    "whyChooseUsPoints",
    getWhyChooseUsPoints,
    {
      onError: e => toast.error(e.message || "Error fetching data"),
    }
  );
  const styles = useStyles();
  console.log(data);
  return (
    <div className={styles.textHolder}>
      {isLoading && <p>Loading...</p>}
      {(data ?? []).map((item, index) => {
        return (
          <div key={index} className={styles.register}>
            {/* <p className={styles.integer}>
              {String(index + 1).padStart(2, "0")}
        </p>*/}
            <div className={styles.qualityCard}>
              <div className={styles.contentBox}>
                {/*} <img
                  className={styles.contenticon}
                  src={DOMAIN + item.image.url}
                  alt="icon"
                /> */}
              </div>
              <div className={styles.qualityContent}>
                <h2 className={styles.header}>{item.title}</h2>
                <p className={styles.subHeader}>{item.description}</p>
              </div>
            </div>
          </div>
        );
      })}

      <div className={styles.bottom}></div>
    </div>
  );
};

export default Points;
