const GenerateFormData = values => {
  const formData = new FormData();
  formData.append("email", values.email);
  formData.append("name", values.name);
  formData.append("eye", values.eye);
  formData.append("symptoms", values.symptoms);
  formData.append("time", values.duration);
  formData.append("nonListed", values.description);
  formData.append("videoConsultation", values.preferVideoConsultation);
  formData.append("contact", values.contact);
  formData.append("DOB", values.dob);
  formData.append("is_video_consultation", values.preferVideoConsultation);
  if (values.preferVideoConsultation) {
    formData.append("paypal_amount", values.paypal_amount);
    formData.append("paypal_token", values.paypal_token);
    formData.append("schedule_token", values.scheduleObject.id);
  }

  values.files.forEach((report, index) => {
    formData.append(`report[${index}]`, report);
  });
  [values.file1, values.file2, values.file3].forEach((image, index) => {
    formData.append(`image[${index}]`, image);
  });
  return formData;
};

export default GenerateFormData;
