import {createTheme} from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#17664e",
    },
    secondary: {
      main: "#D2E6E4",
    },
    common: {
      red: "#F95E5E",
      lightGrey: "#F5F5F7",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      custom: 850,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
