import React from "react";
import SingleCheckboxSection from "./SingleCheckboxSection";

const CheckboxSections = ({ config }) => {
  return (
    <>
      {config.map((item, index) => (
        <SingleCheckboxSection key={index} {...item} />
      ))}
    </>
  );
};

export default CheckboxSections;
