import {Modal, Backdrop, makeStyles, Button} from "@material-ui/core";
import React, {useRef} from "react";
import {useState} from "react";
import Webcam from "react-webcam";

const useStyles = makeStyles(theme => ({
  camera: {
    display: "block",
    margin: "0 auto",
    width: "auto",
    height: "100vh",
  },
  container: {
    position: "relative",
    width: "100vw",
    height: "100vh",
  },
  controls: {
    width: "50%",
    margin: "0 auto",
    position: "absolute",
    bottom: 100,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const Camera = ({open, onClose, onCapture}) => {
  const [faceUser, setFaceUser] = useState(true);
  const camera = useRef(null);
  const styles = useStyles();
  const [showButtons, setShowButtons] = useState(false);

  const capture = React.useCallback(() => {
    if (camera.current) {
      const imageSrc = camera.current.getScreenshot();
      const file = dataURLtoFile(
        imageSrc,
        Math.random().toString(36).slice(2, 12) + ".png"
      );
      onCapture(file);
    }
    //eslint-disable-next-line
  }, [camera]);

  return (
    <Modal open={open} BackdropComponent={Backdrop} onClose={onClose}>
      <div className={styles.container}>
        <Webcam
          ref={camera}
          mirrored={faceUser}
          screenshotFormat="image/png"
          screenshotQuality={1}
          onUserMedia={() => {
            setShowButtons(true);
          }}
          videoConstraints={{
            facingMode: faceUser ? "user" : "environment",
          }}
          className={styles.camera}
        />
        {showButtons && (
          <div className={styles.controls}>
            <Button variant="contained" color="primary" onClick={onClose}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                capture();
                onClose();
              }}>
              Capture
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setFaceUser(p => !p)}>
              Flip
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
Camera.defaultProps = {
  open: false,
  onClose: () => {},
  onCapture: () => {},
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {type: mime});
}

export default Camera;
