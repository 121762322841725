import {makeStyles} from "@material-ui/styles";
import React from "react";
import circleo from "../images/circleo.svg";
// import rectangle from "../assets/about.jpg";
import {getVirtualeyeImage, DOMAIN} from "../api";
import {toast} from "react-toastify";
import {useQuery} from "react-query";

const useStyles = makeStyles(theme => {
  return {
    container: {
      "backgroundColor": theme.palette.common.lightGrey,
      "position": "relative",
      "padding": "70px 0 74px",
      "fontFamily": "Livvic,sans-serrif",
      "marginTop": "20px",
      "z-index": "1",
      "& h2": {
        fontSize: 30,
        color: theme.palette.primary.main,
        fontWeight: 700,
        margin: 0,
      },
      "& p": {
        fontWeight: 400,
        lineHeight: "2rem",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: 0,
        padding: "0 0 70px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: 0,
        padding: "0 0 68px",
      },
    },
    aboutimg: {
      height: 370,
      marginLeft: 155,
      width: "55%",

      [theme.breakpoints.down("md")]: {
        marginLeft: "50px",
        height: "100%",
        width: "300px",
      },

      [theme.breakpoints.down("sm")]: {
        marginLeft: "25px",
      },

      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    circleo: {
      position: "absolute",
      top: 215,
      left: 30,
    },
    // circleo2: {
    //   position: "absolute",
    //   left: "80pc",
    //   [theme.breakpoints.down("lg")]: {
    //     left: "70pc",
    //   },
    // },
    textHolder: {
      maxWidth: "100%",
      margin: "0 ",
      textAlign: "left",
      position: "relative",
      display: "flex",

      [theme.breakpoints.down("sx")]: {},
    },

    circleGroup3: {
      position: "absolute",
      left: "48px",
      top: "48px",

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    mobView: {
      [theme.breakpoints.down("sm")]: {
        margin: "0 ",
      },
    },
    text: {
      position: "relative",
      zIndex: 10,
      margin: "auto 0",
      maxWidth: 500,
      borderRadius: 20,
      // padding: "16px 60px",
      width: "50%",
      [theme.breakpoints.down("md")]: {
        width: "45%",
        wordWrap: "revert",
        padding: "20px",
        margin: "0 ",
      },

      [theme.breakpoints.down("sm")]: {
        margin: " 0 50px 0 10px ",
      },

      [theme.breakpoints.down("xs")]: {
        width: "100% ",
        margin: 0,
      },
    },
    bottom: {
      backgroundImage:
        "linear-gradient(91.51deg, rgba(23, 102, 78, 0.5) -4.3%, rgba(23, 102, 78, 0.1) 103.87%)",
      height: 134,
      borderRadius: 20,
      marginTop: "-60px",
      [theme.breakpoints.down("md")]: {
        margin: "-70px auto 0",
        width: "95%",
      },
    },
    wd50: {
      width: "48%",

      [theme.breakpoints.down("xs")]: {
        width: "0",
      },
    },
  };
});

const About = ({type}) => {
  const {data} = useQuery("virtualeyeimage", getVirtualeyeImage, {
    onError: e => toast.error(e.message || "Error fetching data"),
  });
  const styles = useStyles();
  return (
    <div className={styles.container} id="about">
      <div className={styles.circleGroup3}>
        <img src={circleo} className={styles.circleo} alt="img3" />
      </div>

      <div className={styles.textHolder}>
        <div className={styles.wd50}>
          <img
            src={DOMAIN + data?.[0]?.about_us?.url}
            className={styles.aboutimg}
            alt="img3"
          />
        </div>
        <div className={styles.text}>
          <h2 className={styles.mobView}>About us</h2>
          <p className={styles.aboutUs}>{type}</p>
        </div>
        {/* <div className={styles.bottom}></div> */}
      </div>
    </div>
  );
};

export default About;
