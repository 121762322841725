import {Grid, makeStyles} from "@material-ui/core";
import React from "react";
import {useHistory} from "react-router";
// import Popup from "./PopUp";
const useStyles = makeStyles(themes => ({
  container: {
    position: "relative",
    zIndex: 10,
    background: "#17664E",
    color: "#FFFF",
    fontSize: "12px",
    fontWeight: "500",
  },
}));
function Footer() {
  const classes = useStyles();
  const history = useHistory();
  // const [openPopUp, setOpenPopUp] = useState(false);
  return (
    <div>
      <Grid container className={classes.container} justifyContent="center">
        <Grid item style={{maxWidth: 42, margin: "0 16px"}}>
          <p>@2021</p>
        </Grid>
        <Grid item style={{maxWidth: 42, margin: "0 16px"}}>
          <p
            style={{cursor: "pointer"}}
            onClick={() => history.push("/privacy")}>
            Privacy
          </p>
        </Grid>
        <Grid item style={{maxWidth: 42, margin: "0 16px"}}>
          <p style={{cursor: "pointer"}} onClick={() => history.push("/Terms")}>
            Terms
          </p>
        </Grid>
      </Grid>
      {/* <Popup openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} /> */}
    </div>
  );
}

export default Footer;
