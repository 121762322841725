import {
  Checkbox,
  Grid,
  Box,
  makeStyles,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import {Field, useFormikContext} from "formik";
import React, {useState} from "react";
import {useEffect} from "react";
import {getConsultationDate, getPricing} from "../api";
import BookNow from "./BookNow";
import DatePicker, {formatDate} from "./DatePicker";
import PaypalPayment from "./PaypalPayment";

const firstConfigs = [
  {name: "name", label: "Full Name", type: "text"},
  {name: "email", label: "Email", type: "email"},
  {name: "dob", label: "Date Of Birth", type: "date"},
  {name: "contact", label: "Contact Number", type: "number"},
];

const useStyles = makeStyles(theme => ({
  checkbox: {marginRight: 10},
  checkLabel: {color: theme.palette.primary.main},
  second: {margin: "0 4.5%"},
  padding: {padding: "5% 0 0 10%"},
  form: {
    backgroundColor: "white",
    borderRadius: "5px",
  },
  align: {
    paddingLeft: "5%",
    paddingBottom: "5%",
  },
  gap: {
    marginTop: 20,
  },
  paper: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4%",
    },
  },
  disableDate: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    cursor: "not-allowed",
  },
}));

const Form = () => {
  const [amount, setAmount] = useState(60);
  const [pricingSchema, setPricingSchema] = useState(null);
  const [availableDateTimeSchema, setAvailableDateTimeSchema] = useState(null);
  const [availableTimes, setAvailableTimes] = useState(null);
  const classes = useStyles();
  const formik = useFormikContext();
  const [showSecondPart, setShowSecondPart] = useState(false);

  useEffect(() => {
    let isOkay = true;
    const values = formik.values;
    const names = ["name", "email", "dob", "contact"];
    names.forEach(name => {
      isOkay = isOkay && values[name];
    });
    setShowSecondPart(isOkay);
  }, [formik.values]);

  // const shouldSecondPartBeShown = () => {
  //   let isOkay = true;
  //   const {values, errors, touched} = formik;
  //   const names = ["name", "email", "dob", "contact"];
  //   names.forEach(name => {
  //     isOkay = isOkay && values[name] && !errors[name];
  //   });
  //   return isOkay;
  // };

  useEffect(() => {
    getPricing()
      .then(arr => {
        setPricingSchema(arr);
      })
      .catch(err => {
        alert("An error occurred while trying to fetch the pricing data");
      });
  }, []);

  useEffect(() => {
    if (formik.values.consultDate) {
      const index = availableDateTimeSchema.findIndex(item => {
        return item.date === formik.values.consultDate;
      });
      if (index === -1) {
        console.log("did not find any");
        setAvailableTimes(null);
      } else {
        setAvailableTimes(availableDateTimeSchema[index]["time"]);
      }
    }
  }, [formik.values.consultDate, availableDateTimeSchema]);

  useEffect(() => {
    if (pricingSchema) {
      const isVideoChecked = formik.values.preferVideoConsultation;
      const price = isVideoChecked
        ? pricingSchema[0]["video"]
        : pricingSchema[0]["no_video"];
      setAmount(price);
    }
  }, [pricingSchema, formik.values.preferVideoConsultation]);

  useEffect(() => {
    getConsultationDate()
      .then(arr => {
        setAvailableDateTimeSchema(arr);
      })
      .catch(err => {
        alert("An error occurred while trying to fetch the available dates");
      });
  }, []);

  return (
    <div className={classes.form} style={{paddingBottom: 10}}>
      {formik.isSubmitting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "grid",
            placeItems: "center",
          }}>
          <CircularProgress color="primary" />
        </div>
      )}
      <Typography
        color="#4F4F4F"
        style={{paddingTop: "2%", paddingLeft: "3%"}}
        variant="h6"
        component="h6">
        Personal Information
      </Typography>
      <div style={{backgroundColor: "white"}}>
        <Grid className={classes.padding} container spacing={2}>
          {firstConfigs.map(({label, name, type}) => {
            return (
              <Grid key={name} item xs={12} sm={6}>
                <label>
                  <Box color="#919191">{label}</Box>
                </label>
                <div></div>
                <TextField
                  variant="outlined"
                  color="primary"
                  size="small"
                  name={name}
                  value={formik.values[name]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={formik.isSubmitting}
                  error={formik.touched[name] && Boolean(formik.errors[name])}
                  helperText={formik.touched[name] && formik.errors[name]}
                  type={type}
                  style={{width: "85%"}}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid
          container
          className={classes.second}
          style={{paddingLeft: "4%", marginTop: 20}}>
          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center">
              <Field name="preferVideoConsultation">
                {({field}) => {
                  return (
                    <>
                      <Checkbox
                        className={classes.checkbox}
                        color="primary"
                        {...field}
                        disabled={
                          !availableDateTimeSchema ||
                          formik.values["paypal_token"]
                        }
                      />
                      <span className={classes.checkLabel}>
                        Prefer Video Consultation
                      </span>
                    </>
                  );
                }}
              </Field>
            </Grid>
          </Grid>
        </Grid>
        {showSecondPart && (
          <>
            <div className={classes.gap}></div>
            {formik.values["preferVideoConsultation"] && (
              <Grid className={classes.second} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      width: "75%",
                      paddingLeft: "8%",
                      position: "relative",
                    }}>
                    <Box color="#919191">Pick a date</Box>
                    <div></div>

                    {
                      /*this disables the following datepicker since setting disabled attribute was not cutting it cause this is a custom Component*/
                      formik.values["paypal_token"] && (
                        <div className={classes.disableDate}></div>
                      )
                    }
                    <DatePicker
                      value={formik.values.consultDate}
                      disabled={formik.values["paypal_token"]}
                      onDateChange={({value}) => {
                        formik.setFieldValue("consultDate", formatDate(value));
                        formik.setFieldValue("scheduleObject", null);
                      }}
                      availableDates={availableDateTimeSchema?.map(
                        date => date.date
                      )}
                      placeholder="Select date for video consultation"
                    />
                  </div>
                </Grid>

                {!formik.values["scheduleObject"] && (
                  <>
                    <Grid item className={classes.paper} xs={12} md={6}>
                      <BookNow availableTimes={availableTimes} />
                    </Grid>
                  </>
                )}
                {formik.values["paypal_token"] && (
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: 20,
                        marginTop: 10,
                        marginLeft: -20,
                        width: "80%",
                      }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        alignItems="center">
                        <Grid item>
                          Start time :{" "}
                          {formik.values["scheduleObject"]["start_time"]}
                        </Grid>
                        <Grid item>
                          End time:{" "}
                          {formik.values["scheduleObject"]["end_time"]}
                        </Grid>
                        <Grid item>
                          Price: {formik.values["paypal_amount"]}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )}
                <Grid item xs={10} md={5} style={{marginTop: 20}}>
                  {!formik.values["paypal_token"] &&
                    formik.values["scheduleObject"] && (
                      <>
                        <PaypalPayment
                          amount={amount}
                          afterPaymentComplete={details => {
                            formik.setFieldValue("paypal_token", details.id);
                            formik.setFieldValue(
                              "paypal_amount",
                              details.purchase_units[0].amount.value
                            );
                          }}
                        />
                      </>
                    )}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Form;
