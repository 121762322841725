import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { ReactComponent as FileIcon } from "../assets/file.svg";
import { ReactComponent as Delete } from "../assets/DeleteSmall.svg";

const FileDisplay = ({ file, deleteFile }) => {
  return (
    <Grid item>
      <Paper style={{ padding: 10 }}>
        <Grid container>
          <Grid item xs={2}>
            <FileIcon />
          </Grid>
          <Grid item xs={9} container direction="column">
            <Grid item>{file.name}</Grid>
            <Grid item>
              <i>file size</i>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Delete
              style={{ position: "relative", zIndex: 10 }}
              onClick={deleteFile}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default FileDisplay;
