import axios from "axios";

//never gonna give you up, never gonna let you down, never gonna run around and desert you, never gonna make you cry, never gonna say goodbye, never gonna tell a lie and hurt you

export const DOMAIN = "https://uat.admin.virtualeye.net/";
// export const DOMAIN = "http://localhost:3000/";
// const CONTENTDOMAIN = "http://localhost:8080/content";
// const CONTENTDOMAIN =
//   "https://mocki.io/v1/6242f73c-324d-4184-b0d9-63635cb1df55";

export const getConsultationDate = async () => {
  const response = await axios.get(DOMAIN + "api/consultation_date");
  return response.data;
};

export const getPricing = async () => {
  const response = await axios.get(DOMAIN + "api/pricing");
  return response.data;
};

export const postData = data => {
  return axios.post(DOMAIN + "questioniare", data);
};
export const sendMessage = data => {
  return axios.post(DOMAIN + "api/contact_us", data);
};

export const getHomepageContent = async () => {
  const {data} = await axios.get(DOMAIN + "api/feedbacks");
  return data;
};

export const getWhyChooseUsPoints = async () => {
  const {data} = await axios.get(DOMAIN + "api/why_choose_us");
  return data;
};

export const mockedPlans = {
  title: "Highest Quality At An Affordable Price",
  subtitle: "NO UPFRONT FEE",
  plans: [
    {
      price: "£0",
      description:
        "If our SPECIALIST can not generate a diagnosis or recommendation from the information you've provided",
      // "if our SPECIALIST can not generate a diagnosis or recommendation from the information you've provided",
      link: {
        // text: "Consult now",
        path: "/consult",
      },
    },
    {
      price: "£89",
      description:
        "If the information you provide generates a complete diagnosis & management plan",
      link: {
        // text: "Consult now",
        path: "/consult",
      },
    },
    {
      price: "£150",
      description: "If a VIRTUAL VIDEO  consultation is preferred ",
      // "if our SPECIALIST can not generate a diagnosis or recommendation from the information you've provided",
      link: {
        // text: "Consult now",
        path: "/consult",
      },
    },
  ],
};

export const getPaymentMetaData = id => {
  return axios.get(DOMAIN + "/payment/" + id);
};

export const postPayment = ({user_token, paypal_token, amount}) => {
  return axios.post(`${DOMAIN}payment/${user_token}/${paypal_token}/${amount}`);
};

export const getVirtualeyeImage = async () => {
  const {data} = await axios.get(DOMAIN + "api/sliders");
  return data;
};

export const getPolicy = async () => {
  const {data} = await axios.get(DOMAIN + "api/sliders");
  return data;
};
