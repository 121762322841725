import React, {useState} from "react";
import {
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router";

const DrawerComponent = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const useStyles = makeStyles(theme => ({
    drawer: {
      width: "100%",
    },
    paper: {
      background: "#D2E6E4",
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        className={classes.drawer}
        classes={{paper: classes.paper}}>
        <List>
          <ListItem divider button>
            <ListItemIcon>
              <ListItemText onClick={() => setOpenDrawer(false)}>
                {" "}
                <Button className={classes.items}> Home</Button>{" "}
              </ListItemText>
            </ListItemIcon>
          </ListItem>
          <ListItem divider button>
            <ListItemIcon>
              <ListItemText onClick={() => setOpenDrawer(false)}>
                {" "}
                <Button className={classes.items}>
                  {" "}
                  <a
                    style={{textDecoration: "none", color: "inherit"}}
                    href="#about">
                    About Us
                  </a>
                </Button>{" "}
              </ListItemText>
            </ListItemIcon>
          </ListItem>
          <ListItem divider button>
            <ListItemIcon>
              <ListItemText onClick={() => setOpenDrawer(false)}>
                {" "}
                <Button className={classes.items}>
                  {" "}
                  <a
                    style={{textDecoration: "none", color: "inherit"}}
                    href="#price">
                    Our Plans
                  </a>
                </Button>{" "}
              </ListItemText>
            </ListItemIcon>
          </ListItem>
          <ListItem divider button>
            <ListItemIcon>
              <ListItemText onClick={() => setOpenDrawer(false)}>
                {" "}
                <Button className={classes.items}>
                  {" "}
                  <a
                    style={{textDecoration: "none", color: "inherit"}}
                    href="#contact">
                    Contact Us
                  </a>
                </Button>{" "}
              </ListItemText>
            </ListItemIcon>
          </ListItem>
          <ListItem divider button>
            <ListItemIcon>
              <ListItemText>
                {" "}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/consult")}
                  className={classes.items}>
                  Consult Now
                </Button>{" "}
              </ListItemText>
            </ListItemIcon>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  );
};

export default DrawerComponent;
