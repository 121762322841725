import { toast } from "react-toastify";

export const onPaypalApprove = (data, actions, afterPaymentComplete) => {
    // This function captures the funds from the transaction.
    return actions.order.capture().then(function (details) {
        // This function shows a transaction success message to your buyer.
        console.log(details);
        afterPaymentComplete(details);
        toast.success("Transaction completed by " + details.payer.name.given_name);
    });
};

export const paypalCreateOrder = (data, actions, amount) => {
    return actions.order.create({
        purchase_units: [
            {
                amount: {
                    value: amount,
                },
            },
        ],
    });
};
