import React from "react";
import {Grid, AppBar, Toolbar, Typography, Box} from "@material-ui/core";
import "./Stepper.css";
import "./Header.css";
import {useHistory} from "react-router";

function Header({elevation, padding, z}) {
  const history = useHistory();
  return (
    <div>
      <Grid container>
        <AppBar
          position="relative"
          elevation={elevation || 0}
          style={{paddingBottom: padding, zIndex: z || 0}}
          color="white">
          <Toolbar>
            <img
              onClick={() => history.push("/")}
              src="images/logo.png"
              alt=""
            />
            <div className="typo">
              <Typography color="primary" variant="h5">
                Virtual Eye Consultant
              </Typography>
              <Typography variant="h6">
                <Box color="#4F4F4F">We bring eye care to you</Box>
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
      </Grid>
    </div>
  );
}

export default Header;
