import React from "react";
import {useState} from "react";
import {useEffect} from "react";
import {useHistory, useParams} from "react-router";
import {toast, ToastContainer} from "react-toastify";
import {getPaymentMetaData, postPayment} from "../api";
import PaypalPayment from "../components/PaypalPayment";

const Payment = () => {
  const {id} = useParams();
  const [metaData, setMetaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [paypalToken, setPaypalToken] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getPaymentMetaData(id)
        .then(response => {
          setLoading(false);
          const metaData = response.data;
          console.log(metaData);
          if (metaData.email && metaData.amount && String(metaData.paid)) {
            setMetaData(metaData);
          } else {
            toast.error("Invalid token");
          }
        })
        .catch(err => {
          setLoading(false);
          toast.error(err.message || "Failed to load metadata");
        });
    }
  }, [id]);

  useEffect(() => {
    if (amount && paypalToken) {
      postPayment({user_token: id, paypal_token: paypalToken, amount})
        .then(res => {
          history.push("/thankyou");
        })
        .catch(err => {
          toast.error(
            err.message ||
              "An error occurred while submitting your payment. Please try again later"
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, paypalToken, id]);

  const renderRest = () => (
    <div>
      <p>Your email: {metaData.email}</p>
      <p>Amount: £{metaData.amount}</p>
      {/* <p>Paid: {String(metaData.paid)}</p> */}
      {!metaData.paid && (
        <PaypalPayment
          amount={metaData.amount}
          afterPaymentComplete={details => {
            setPaypalToken(details.id);
            setAmount(details.purchase_units[0].amount.value);
          }}
        />
      )}
    </div>
  );

  return (
    <div
      style={{
        textAlign: "center",
        maxWidth: 400,
        margin: "20vh auto",
        backgroundColor: "rgba(0,0,0,0.1)",
        padding: "20px 40px",
        borderRadius: 10,
      }}>
      <ToastContainer />
      {loading && <p>Loading...</p>}
      {metaData && renderRest()}
    </div>
  );
};

export default Payment;
