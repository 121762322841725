import * as React from "react";
import {DatePickerComponent} from "@syncfusion/ej2-react-calendars";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";

export const formatDate = date => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const DatePicker = props => {
  const disabledDate = args => {
    args.isDisabled = true;
    const currentDate = formatDate(args.date);
    props.availableDates.forEach(date => {
      if (date === currentDate) {
        args.isDisabled = false;
      }
    });
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <div className="datetimepicker-control-section">
          <DatePickerComponent
            value={props.value}
            allowEdit={false}
            showTodayButton={false}
            openOnFocus={true}
            renderDayCell={disabledDate}
            placeholder={props.placeholder}
            format="dd MMM-yyyy"
            onChange={props.onDateChange}
            disabled={props.disabled}
            enabled={!props.disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
