import {useFormikContext} from "formik";

const useDisableButton = activeStep => {
  const {
    values: {
      symptoms,
      eye,
      duration,
      file1,
      file2,
      file3,
      name,
      email,
      dob,
      contact,
      preferVideoConsultation,
      paypal_token,
      scheduleObject,
    },
  } = useFormikContext();
  switch (activeStep) {
    case 0:
      if (symptoms.length === 0) {
        return {message: "Please specify your symptoms"};
      }
      if (eye === "") {
        return {message: "Please specify your eye"};
      }
      if (duration === "") {
        return {message: "Please specify your duration"};
      }
      return false;
    case 1:
      const files = [file1, file2, file3];
      let numberOfEmptyFiles = 0;
      files.forEach(file => {
        if (file === null) {
          numberOfEmptyFiles++;
        }
      });
      if (numberOfEmptyFiles !== 0) {
        return {message: `${numberOfEmptyFiles} image files missing`};
      }

      return false;
    case 2:
      if (name.length === 0) {
        return {message: "please fill the name."};
      }
      if (email.length === 0) {
        return {message: "please fill the email."};
      }
      if (dob.length === 0) {
        return {message: "please fill the Date of Birth."};
      }
      if (contact.length === 0) {
        return {message: "please fill the contact."};
      }

      if (preferVideoConsultation && !scheduleObject) {
        return {message: "Please select the consultation date"};
      }

      if (preferVideoConsultation && !paypal_token) {
        return {message: "pre payment is required for video consultation"};
      }

      return false;

    default:
      return true;
  }
};

export default useDisableButton;
