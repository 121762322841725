import React from 'react'
import {
    Grid,
    makeStyles,
    Paper,
    Button,
} from "@material-ui/core";
import { useFormikContext } from "formik";

const useStyles = makeStyles(theme => ({
    paperStyle: {
        padding: 10,
        marginTop: 10,
        width: "75%",
        [theme.breakpoints.down('lg')]: {
            width: "73.5%",
        },
        [theme.breakpoints.down('md')]: {
            width: "71%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "82%",
        },
    }
}))

function BookNow({ availableTimes, }) {
    const formik = useFormikContext();
    const classes = useStyles();
    return (
        <>
            {availableTimes &&
                availableTimes.map(({ id, start_time, end_time }) => {
                    return (
                        <Paper
                            key={id}
                            elevation={3}
                            className={classes.paperStyle}>
                            <Grid
                                container
                                justifyContent="space-between"
                                spacing={2}
                                alignItems="center">
                                <Grid item>Start time : {start_time}</Grid>
                                <Grid item>End time: {end_time}</Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            formik.setFieldValue("scheduleObject", {
                                                id,
                                                start_time,
                                                end_time,
                                            });
                                        }}
                                        variant="contained"
                                        color="primary">
                                        Book now
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    );
                })}

        </>
    )
}

export default BookNow
